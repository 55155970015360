<!-- eslint-disable -->
<template>
  <loading v-model:active="isLoading" :is-full-page="fullPage" />
  <div class="row">
      <div class="col-lg-4">
        <div class="card br-t-white">
          <img class="card-img-top img-fluid" src="/img/img-kip.png" alt="#">
          <div class="card-body">
            <ul class="list-inline">
              <li class="pr-5">Informasi Saldo KIP</li>
            </ul>
            <h3>Rp. {{ $filters.formatRp(saldoKip) }}</h3>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="card">
          <div class="card-header card-default">
            <p id="idExsampel" class="text-muted" >
              Rincian tagihan pembayaran mahasiswa
            </p>
          </div>
          <div class="card-body padding-t-0">
            <template v-for="(row, num) in rinciTagihan" :key="row.id">
              <small class="text-muted text-fl">{{ num+1}}. {{ row.uraian }} </small>
              <p class="text-right mb-10"><small>Rp.</small> {{ $filters.formatRp(row.jumlah) }}</p>
            </template>
            <hr>
            <small class="text-muted text-fl">Total Tagihan</small>
            <p class="text-right"><small>Rp.</small> {{ $filters.formatRp(totalTagihan) }} </p>
            <button class="btn btn-success box-shadow btn-rounded mt-3 float-right" v-on:click="konfirmasiPembayaran">Proses Pembayaran</button>
            <router-link to="/keuangan/tagihan" class="btn btn-warning box-shadow btn-rounded mt-3 float-right" >Kembali</router-link>
          </div>
        </div>
      </div>
  </div>
  <div class="modal fade konfirmasi-modal" tabindex="-1" role="dialog" aria-labelledby="myDefaultModalLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"
              class="fa fa-times"></span></button>
          <h5 class="modal-title" id="myDefaultModalLabel">Konfirmasi</h5>
        </div>
        <div class="modal-body">
          <div class="widget white-bg">
            <div class="row">
              <div class="col-md-12 col-xs-12"> <strong>Anda akan melakukan pembayaran tagihan ini menggukan saldo KIP</strong>
                <br>
                <p class="text-muted">Jumlah tagihan Rp. {{ $filters.formatRp(totalTagihan) }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-warning" data-dismiss="modal">No, Tidak</button>
          <button type="button" v-on:click="prosesPembayaran()" class="btn btn-success">Yes, Setuju</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint no-multi-spaces: [2, { exceptions: { "VariableDeclarator": true } }] */
/* global $ */
import Loading from 'vue-loading-overlay'
import { getKIP, getTagihan, payKip } from '@/services/keu.service'
import { useStore } from 'vuex'
export default {
  name: 'SpadaInfoAkun',
  components: {
    Loading
  },
  data () {
    const store = useStore()
    const users = store.getters.user
    return {
      userName: users.username,
      profil: {},
      saldoKip: 0,
      totalTagihan: 0,
      rinciTagihan: [],
      isRegister: false,
      isLoading: false,
      fullPage: true,
      errorData: {}
    }
  },
  created () {
    this.handleSaldo()
    this.handleTagihan()
  },
  methods: {
    async handleTagihan () {
      this.isLoading = true
      getTagihan({ nipd: this.userName }).then(response => {
        const rinci = response.records
        const total = response.total
        this.totalTagihan = total
        this.rinciTagihan = rinci
        this.isLoading = false
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        this.isLoading = false
      })
    },
    async handleSaldo () {
      getKIP({ nipd: this.userName }).then(response => {
        const saldo = response.saldo
        this.saldoKip = saldo
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
      })
    },
    async konfirmasiPembayaran () {
      const totTghn = this.totalTagihan
      const totSldo = this.saldoKip
      if (totSldo >= totTghn) {
        $('.konfirmasi-modal').modal('show')
      } else {
        $.toast({
          heading: 'Error !',
          text: 'Jumlah saldo tidak mencukupi',
          position: 'top-right',
          loaderBg: '#fff',
          icon: 'error',
          hideAfter: 3000,
          stack: 1
        })
      }
    },
    async prosesPembayaran () {
      const params = {
        nipd: this.userName,
        tagihan: this.totalTagihan
      }
      this.isLoading = true
      payKip(params).then(response => {
        if (response.error_code !== 200) {
          $.toast({
            heading: 'Error !',
            text: response.messages,
            position: 'top-right',
            loaderBg: '#fff',
            icon: 'error',
            hideAfter: 3000,
            stack: 1
          })
        } else {
          $.toast({
            heading: 'Good job!',
            text: 'Transaksi berhasil di proses',
            position: 'top-right',
            loaderBg: '#fff',
            icon: 'success',
            hideAfter: 3000,
            stack: 1
          })
          setTimeout(() => {
            this.$router.push('/keuangan/tagihan')
          }, 3000)
        }
        this.isLoading = false
        $('.konfirmasi-modal').modal('hide')
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        this.isLoading = false
      })
    }
  }
}
</script>
