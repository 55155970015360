<!-- eslint-disable -->
<template>
  <loading v-model:active="isLoading" :is-full-page="fullPage" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header bg-warning card-default">
            <p id="idExsampel">
              Informasi data tagihan pembayaran mahasiswa
            </p>
          </div>
          <div class="card-body">
            <template v-for="(row, num) in rinciTagihan" :key="row.id">
              <small class="text-muted text-fl">{{ num+1}}. {{ row.uraian }} </small>
              <p class="text-right mb-10"><small>Rp.</small> {{ $filters.formatRp(row.jumlah) }}</p>
            </template>
            <hr>
            <small class="text-muted">Total Tagihan</small>
            <p class="text-right"><small>Rp.</small> {{ $filters.formatRp(totalTagihan) }} </p>
          </div>
        </div>
        <div class="card" v-show="isRegister" >
          <div class="card-header card-default">
            <p id="idExsampel" class="text-muted" >
              Halaman informasi Pembayaran Tagihan
            </p>
          </div>
          <div class="card-body padding-t-0">
            <div class="alert bg-warning alert-dismissible" role="alert"> <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>Anda tidak memiliki tagihan pembayaran!. anda dapat membuat tagihan dengan mengklik tombol Buat Tagihan </div>
            <router-link to="/registrasi-akun-spada" v-show="isRegister" href="javascript: void(0);" class="btn btn-success btn-rounded box-shadow">Buat Tagihan</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3">
        <div class="card">
          <img class="card-img-top img-fluid" src="/img/bmi.jpg" alt="#">
          <div class="card-body">
            <ul class="list-inline">
              <li class="pr-4">Pembayaran Melalui Bank Muamalat</li>
            </ul>
            <router-link to="/keuangan/tagihan/slip-bmi" class="btn btn-success box-shadow btn-rounded mt-3">Slip Tagihan</router-link>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card">
          <img class="card-img-top img-fluid" src="/img/bsi.png" alt="#">
          <div class="card-body">
            <ul class="list-inline">
              <li class="pr-4">Pembayaran Melalui Bank Syariah Indonesia</li>
            </ul>
            <router-link to="/keuangan/tagihan/slip-bsi" class="btn btn-success box-shadow btn-rounded mt-3">Slip Tagihan</router-link>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card">
          <img class="card-img-top img-fluid" src="/img/bmm.png" alt="#">
          <div class="card-body">
            <ul class="list-inline">
              <li class="pr-4">Pembayaran Melalui BPRS Metro Madani</li>
            </ul>
            <router-link to="/keuangan/tagihan/slip-bmm" class="btn btn-success box-shadow btn-rounded mt-3">Slip Tagihan</router-link>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card">
          <img class="card-img-top img-fluid" src="/img/pay-kip.png" alt="#">
          <div class="card-body">
            <ul class="list-inline">
              <li class="pr-4">Pembayaran Menggunakan Saldo KIP</li>
            </ul>
            <router-link to="/keuangan/tagihan/payment-kip" class="btn btn-success box-shadow btn-rounded mt-3">Bayar Tagihan</router-link>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
/* eslint no-multi-spaces: [2, { exceptions: { "VariableDeclarator": true } }] */
import Loading from 'vue-loading-overlay'
import { getTagihan } from '@/services/keu.service'
import { useStore } from 'vuex'
export default {
  name: 'TagihanInfo',
  components: {
    Loading
  },
  data () {
    const store = useStore()
    const users = store.getters.user
    return {
      userName: users.username,
      profil: {},
      totalTagihan: 0,
      rinciTagihan: [],
      isRegister: false,
      isLoading: false,
      fullPage: true,
      errorMsg: {}
    }
  },
  created () {
    this.handleTagihan()
  },
  methods: {
    async handleTagihan () {
      this.isLoading = true
      getTagihan({ nipd: this.userName }).then(response => {
        const rinci = response.records
        const total = response.total
        this.totalTagihan = total
        this.rinciTagihan = rinci
        this.isLoading = false
      }).catch(error => {
        this.errorMsg = (error.response) ? {} : {}
        this.isLoading = false
      })
    }
  }
}
</script>
